import React, { useState, useEffect } from 'react'
import './Destination.css'
import axios from 'axios'
import { useAuth } from '../../Components/Auth/auth';
import { useNavigate } from 'react-router-dom';
const Destination = () => {
    const [destination, setDestination] = useState([])
    const [allDestination, setAllDestination] = useState(false)
    const auth = useAuth();
    const [gridData, setGridData] = useState([])
    const [renderApp, setRenderApp] = useState(false)
    const navigation = useNavigate();

    const loadData = async () => {
        try {
            const response = await axios.get(`${auth.baseURL}/api/get-location`);
            response?.status === 200 && setDestination(response?.data?.data)
            setRenderApp(true)
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (renderApp) {
            setGridData(destination.slice(0, 5))
        }
    }, [destination])

    const handleSearch = async (id) => {
        try {
            const response = await axios.post(`${auth.baseURL}/api/search-by-location`, { location: id, })
            navigation('/searchResult', { state: { result: response.data.data } })

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='px-lg-5'>
            <div className="text-center fancy-font fs-1">
                Destination Lists
            </div>
            <div className="big-heading text-center">Go Exotic Places</div>
            <div className="grid-image-container mt-4">
                {allDestination ?
                    <div className="">
                        <div className="d-flex flex-wrap justify-content-center">
                            {destination && destination.map((destinatio, index) => (
                                <div onClick={(e) => { handleSearch(destinatio.id) }} key={index} className='position-relative destination-small-grid'>
                                    <div className="overlay-wrapper">
                                        <img className='grid-image' src={destinatio.thumbnail} lazy alt="" />
                                    </div>
                                    <div className="overlay-content-container">
                                        <div className="country-name">
                                            {destinatio.name}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    <div className="parent">
                        {gridData && gridData.map((destinatio, index) => (
                            <div onClick={(e) => { handleSearch(destinatio.id) }} key={index} className={`div${index + 1} position-relative`}>
                            <div className="overlay-wrapper">
                                <img className='grid-image' src={destinatio.thumbnail} alt="" />
                            </div>
                            <div className="overlay-content-container">
                                <div className="country-name">
                                    {destinatio.name}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                }
                <div className="mt-4 d-flex justify-content-end">
                    <button onClick={() => { setAllDestination(!allDestination) }} className='btn secondary-border-btn'>{
                        allDestination ? 'Hide' :
                            'Show All'}</button>
                </div>
            </div>
        </div>
    )
}

export default Destination