import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './Login.css'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { useAuth } from '../../Components/Auth/auth';
const ResetPass = () => {
    const id = useParams().id;
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate()
    const [successMsg, setSuccessMsg] = useState('');
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const auth = useAuth();
    const onSubmit = async (data) => {
        try {
            setErrMsg('')
            setSuccessMsg('')
            const response = await axios.post(`${auth.baseURL}/api/update-password`, {
                password: data.new_password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${id}`
                }
            })
            if (response.status === 200) {
                setSuccessMsg(response.data.msg)
            }
        } catch (err) {
            setErrMsg(err?.response?.data?.msg)
            console.log(err)
        }

    }
    return (
        <div className='login-container'>
            <div className="login-wrapper p-md-4">
                <div className="big-heading fs-4 fw-bold text-center">
                    Forgot Password
                </div>
                {errMsg.length > 0 &&
                    <div className="error-display-container">
                        {errMsg}
                    </div>
                }
                {successMsg.length > 0 &&
                    <div className="success-display-container">
                        {successMsg}
                    </div>
                }
                <form onSubmit={handleSubmit(onSubmit)} className='mt-4' action="">
                    <div className="booking-contact-name-container mt-3 px-3">
                        <div className="row mb-3">
                            <label className='px-0' htmlFor="">New Password</label>
                            <input
                                {...register('new_password', {
                                    required: true,
                                    minLength: 8,
                                })}
                                type="password"
                                className={errors?.new_password ? "form-control border-danger" : "form-control"} />
                            {errors?.new_password?.type === "required" && <p className='mt-1 px-0 mb-0 px-0 text-danger'>This field is required</p>}
                            {errors?.new_password?.type === "minLength" && <p className='mt-1 px-0 mb-0 text-danger'>Password length is short</p>}

                        </div>
                        <div className="row mb-3">
                            <label className='px-0' htmlFor="">Confirm Password</label>
                            <input
                                {...register('confirm_password', {
                                    required: true,
                                    minLength: 8,
                                    validate: (val) => {
                                        if (watch('new_password') != val) {
                                            return "Your passwords do no match";
                                        }
                                    },
                                })}
                                type="password"
                                className={errors?.confirm_password ? "form-control border-danger" : "form-control"} />
                            {errors?.confirm_password?.type === "required" && <p className='mt-1 px-0 mb-0 px-0 text-danger'>This field is required</p>}
                            {errors?.confirm_password?.type === "minLength" && <p className='mt-1 px-0 mb-0 text-danger'>Password length is short</p>}
                            {errors?.confirm_password?.type === "validate" && <p className='mt-1 px-0 mb-0 text-danger'>Password does not match</p>}
                        </div>
                        <div className="row mb-3">
                            <input className='btn primary-btn text-white p-2' type="submit" value={'SEND'} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPass