import React, { useState, useEffect } from 'react'
import Package from '../../Components/Package/Package'
import axios from 'axios'
import Loading from '../../Components/Loading/Loading'
import { useAuth } from '../../Components/Auth/auth';

const AllPackage = () => {
    const auth = useAuth();
    const [topPackage, setTopPackage] = useState([])
    const [renderApp, setRenderApp] = useState(false)
    const loadData = async () => {
        try {
            const response = await Promise.all([
                axios.get(`${auth.baseURL}/api/get-package`),
            ])
            setTopPackage(response[0].data.data)
            setRenderApp(true)
        } catch (err) {
            setRenderApp(true)
            console.log(err)
        }
    }

    useEffect(() => {
        loadData()
    }, []);
    return (
        <div className='my-4 container px-md-5'>
            {!renderApp ?
                <Loading /> :
                <>
                    <h3 className="big-heading">
                        All Packages
                    </h3>
                    <div className="feature-card-wrapper d-flex mt-4">
                        {
                            renderApp &&
                            <div className='d-flex justify-content-md-start justify-content-center flex-wrap'>
                                {
                                    topPackage.map((packages) => (
                                        <Package data={packages} />
                                    ))
                                }
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default AllPackage