import React from 'react'
import Destination from '../../Components/Destination/Destination'
import scene from '../../image/Home/image-12.jpeg'
import { Link } from 'react-router-dom'
const DestinationPage = () => {
    return (
        <div className='py-5 px-md-5'>
            <Destination />
            <div className="why-us-container container px-md-5 my-5 pt-5">
                <div className="row row-cols-lg-2 row-cols-1">
                    <div className="col order-lg-0 order-1">
                        <div className="fancy-font fs-2">
                            Why Choose Us?
                        </div>
                        <div className="big-heading">
                            Plan Your Trip with Swissa's
                        </div>
                        <div className="light-text-bottom pt-4">
                            <p>
                                There are many variations of passages of available but the majority have suffered alteration in some form, by injected hum randomised words which don't look even slightly.
                            </p>
                        </div>
                        <div className="list-container pt-4 lh-lg">
                            <ul className='check-list'>
                                <li>
                                    <span>
                                        Invest in your simply neighborhood
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Support people in free text extreme need
                                    </span>
                                </li>
                                <li>
                                    <span> Largest global industrial business community</span> </li>
                            </ul>
                        </div>
                        <div className=" pt-4">
                            <Link to='/contact' className='primary-btn btn'>
                                Book with us now
                            </Link>
                        </div>
                    </div>
                    <div className="col order-lg-1 order-0 mb-lg-0 mb-4 text-center position-relative">
                        <div className="grey-circle"></div>
                        <img className='img-fluid circle-img' src={scene} alt="" />
                        <div className="red-circle"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DestinationPage