import React, { useState, useEffect } from 'react'
import { useAuth } from '../../Components/Auth/auth';
import './Package.css'
import axios from 'axios';
import { Link } from 'react-router-dom';
const Package = ({ data }) => {
    const id = data.id;
    const totalStars = 5;
    const activeStars = 4;
    const auth = useAuth();
    const [wishlist, setWishlist] = useState([])
    const [load, setLoad] = useState(false)

    const [isWishlist, setIsWishlist] = useState(false)
    const removeWishList = async () => {
        try {
            const response = await axios.delete(`${auth.baseURL}/api/remove-wishlist/${id}`,
                { withCredentials: true }, {
            })
            { response.status === 200 && setIsWishlist(false); document.getElementById('package-wishlist-btn').classList.remove('added') }
        } catch (err) {
            console.log(err)
        }
    }
    const addWishlist = async () => {
        try {
            const response = await axios.post(`${auth.baseURL}/api/add-wishlist`, { package: id },
                { withCredentials: true }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            { response.status === 200 && setIsWishlist(true) }
        } catch (err) {
            console.log(err)
        }
    }
    const checkWishlist = () => {
        if (auth.isLogin) {
            {
                wishlist.length > 0 &&
                    wishlist.map((wish, index) => {
                        Number(wish.package) === Number(id) && setIsWishlist(true)
                    })
            }
        }
    }

    const loadData = async () => {
        try {
            if (auth.isLogin) {
                try {
                    const resp = await axios.get(`${auth.baseURL}/api/get-wishlist`, { withCredentials: true })
                    setWishlist(resp.data.data)

                } catch (err) {
                    console.log(err)
                }
            }

            setLoad(true)
        } catch (err) {
            console.log(err)
            setLoad(true)
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        checkWishlist();
    }, [wishlist])
    return (
        <Link to={`/package/${id}`} className="rounded me-md-4 mt-4">
            <div className="feature-card-child position-relative mx-md-0 mx-auto">
                <div className="overlay-wrapper">
                    <img className='img-fluid rounded' src={data.image} alt="" />
                </div>
                <div className="overlay-content">
                    <div className="d-flex h-100 flex-column justify-content-between">
                        <div className="featured-top d-flex justify-content-between">
                            <div className="featured-card pt-2">
                                featured
                            </div>
                            {auth.isLogin ?
                                <button id='package-wishlist-btn'
                                    onClick={
                                        isWishlist ? removeWishList :
                                            addWishlist
                                    } className={isWishlist ? 'btn react-icon-container added me-3' : 'btn react-icon-container not-added me-3'}>
                                    <span className="material-symbols-outlined">
                                        favorite
                                    </span>
                                </button> :
                                <Link to='/login' className='btn secondary-btn'>
                                    <span className="material-symbols-outlined me-2">
                                        favorite
                                    </span>
                                    Wishlist
                                </Link>
                            }
                        </div>
                        <div className="featured-bottom  mt-md-auto mt-0">
                            <div className="action-package d-flex justify-content-between">
                                <div className="review-container d-flex">

                                    {[...new Array(totalStars)].map((arr, index) => {
                                        return index < activeStars ?
                                            <div key={index} className="review-star">
                                                <span className="material-symbols-outlined">
                                                    star
                                                </span>
                                            </div> :
                                            <div key={index} className="review-star-half">
                                                <span className="material-symbols-outlined">
                                                    star
                                                </span>
                                            </div>;
                                    })}
                                </div>
                            </div>
                            <div className="package-name">
                                <div className="">{data.package_title}</div>
                            </div>
                            <div className="package-loaction d-flex align-items-center">
                                <span>
                                    {data.location}
                                </span>
                            </div>
                            <div className="package-price">
                                From <span className='ms-2 price'>${data.starting_price}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default Package