import React from 'react'
import './About.css'
import scene from '../../image/Home/image-12.jpeg'
import ParallexHome from '../../Components/ParallexHome/ParallexHome'
import { Link } from 'react-router-dom'
const About = () => {
    return (
        <div>

            <div className="aboout-us-container container px-md-5 mb-5">
                <div className="big-heading mt-5 fs-2">
                    About Us
                </div>
                <p className='mt-3 text-justify'>
                    Swissa Pvt. Ltd, an adventure company managed and operated by Nepali professionals, was formed in 1992 and has become popular among the adventure seeker around the globe ever since. We have been able to maintain the standard of quality service along with guaranteed satisfaction since our establishment to till date in arranging and operating safe and fun river trips throughout Nepal. Whether you are a nervous beginner or an experienced thrill-seeker, we will ensure the adventure for lifetime.

                    Our internationally experienced guides, skilled boat captains, chefs and other river crews are fully trained in first-aid and river rescue as well as committed to deliver an extra value exploring the remote countryside and natural beauty of Nepal, which would be almost impossible without river excursion. No matter whether you are in group or an individual, we can organize a trip on wide range of rivers by raft and kayak.
                </p>
            </div>
            <div className="why-us-container container mt-5 px-md-5 mb-5">
                <div className="row row-cols-lg-2 row-cols-1">
                    <div className="col order-lg-0 order-1">
                        <div className="fancy-font fs-2">
                            Why Choose Us?
                        </div>
                        <div className="big-heading">
                            Plan Your Trip with Swissa's
                        </div>
                        <div className="light-text-bottom pt-4">
                            <p>
                                There are many variations of passages of available but the majority have suffered alteration in some form, by injected hum randomised words which don't look even slightly.
                            </p>
                        </div>
                        <div className="list-container pt-4 lh-lg">
                            <ul className='check-list'>
                                <li>
                                    <span>
                                        Invest in your simply neighborhood
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Support people in free text extreme need
                                    </span>
                                </li>
                                <li>
                                    <span> Largest global industrial business community</span> </li>
                            </ul>
                        </div>
                        <div className=" pt-4">
                            <Link to='/contact' className='primary-btn btn'>
                                Book with us now
                            </Link>
                        </div>
                    </div>
                    <div className="col order-lg-1 order-0 mb-lg-0 mb-4 text-center position-relative">
                        <div className="grey-circle"></div>
                        <img className='img-fluid circle-img' src={scene} alt="" />
                        <div className="red-circle"></div>
                    </div>
                </div>
            </div>
            <ParallexHome />
        </div>
    )
}

export default About