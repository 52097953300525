import React, { useState, useEffect } from 'react'
import logo from '../../image/company/1.png'
import { Link, useLocation } from 'react-router-dom';
import './Footer.css'
const Footer = () => {
    const location = useLocation().pathname.slice(1, 5);
    const [showNav, setShowNav] = useState(true);
    useEffect(() => {
        { location === 'user' ? setShowNav(false) : setShowNav(true) }
    }, [location])
    const moveToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    return (
        <>
            {showNav &&

                <div className='footer'>
                    <div className="pt-5">
                        <div className="container px-md-5 py-5">
                            <div className="row row-cols-lg-3 g-4 row-cols-md-2 row-cols-1">
                                <div className="col px-3">
                                    <div className="">
                                    Welcome to our Swissa Travel <span className='text-white'>Kathmandu</span> . Trekking, Rafting Bunjy, Jungle Safari and much more.
                                    </div>
                                    <hr />
                                    <div className="contact-details">
                                        <div className="call mb-3">
                                        <i class="bi bi-whatsapp text-success"></i>
                                            <span className='ms-3'>+977 9846047176</span>
                                        </div>
                                        <div className="email mb-3">
                                            <i className="bi bi-envelope-fill text-primary icon"></i>
                                            <span className='ms-3'>info@swissatrek.com</span>
                                        </div>
                                        <div className="location">
                                            <i className="bi bi-geo-alt-fill text-primary icon"></i>
                                            <span className='ms-3'>Paknajol,Thamel,Kathmandu</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col px-3 ps-md-5">
                                    <div className="">
                                    Welcome to our Swissa Travel <span className='text-white'>Pokhara</span> . Trekking, Rafting Bunjy, Jungle Safari and much more.
                                    </div>
                                    <hr />
                                    <div className="contact-details">
                                        <div className="call mb-3">
                                        <i class="bi bi-whatsapp text-success"></i>
                                            <span className='ms-3'>+977-61-453839</span>
                                        </div>
                                       
                                        <div className="location">
                                            <i className="bi bi-geo-alt-fill text-primary icon"></i>
                                            <span className='ms-3'>Fairmount hotel  @ground floor,Halanchowk,Lakeside,Pokhara</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col ps-md-5 d-flex flex-column align-items-center">
                                    <div className="footer-heading">
                                        Company
                                    </div>
                                    <ul className='list-unstyled mt-3'>
                                        <li>
                                            <Link to='/about'>
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/destination'>
                                                Destination
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/contact'>
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className=" ps-md-5 ms-md-5">
                            <div className="copy-right-wrapper d-flex flex-wrap">
                                <div onClick={moveToTop} className="scroll-top-container pe-auto bg-primary d-flex align-items-center justify-content-center">
                                    <i className="bi bi-arrow-up"></i>
                                </div>
                                <div className="py-4 ms-3 d-flex">
                                    <a className='search-container' target={'_blank'} href="https://www.facebook.com/SwissaTravel">
                                        <i className="bi bi-facebook"></i>
                                    </a>
                                    <a className='search-container' target={'_blank'} href="https://api.whatsapp.com/send?phone=%2B9779846047176&fbclid=IwAR0Dn18WslAuAYug-nHCQufuckGscboZ3c0aJ2oNxZKK0qXrtVFKeDZZKss">
                                        <i class="bi bi-whatsapp"></i>
                                    </a>
                                </div>
                                <div className="copyright-text ms-auto me-5 pe-5 py-auto d-flex align-items-center my-md-0 my-3">
                                    <div className="">
                                        <p className='mb-0'>
                                            &copy; Copyright 2023 by Swissa
                                        </p>
                                        <p className='mb-0'>
                                            Developed By <a className='text-decoration-none text-primary' href="https://www.softsaro.com/">Softsaro</a>
                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default Footer