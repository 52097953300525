import React from 'react'
import Map from '../../Components/Map/Map'
import './Contact.css'
const Contact = () => {
    const location = {
        address: '1600 Amphitheatre Parkway, Mountain View, california.',
        lat: 37.42216,
        lng: -122.08427,
    }
    return (
        <div className='container px-md-5 mt-5'>
            <div className="fancy-font fs-2">
                Get in touch with us
            </div>
            <div className="big-heading mb-3 fs-2">
                Our Headquater
            </div>
            <div className="row ">
                <div className="col-md-8 col-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.0372184315884!2d85.30372838073231!3d27.716137114752858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19c8a7e0a42d%3A0xa588f5174eff7a6e!2sSwissatrek!5e0!3m2!1sen!2snp!4v1712489938807!5m2!1sen!2snp" style={{ "border": 0, "width": "100%", height: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="col-md-4 col-12 mt-md-0 mt-3 mb-5">
                    <div className="contact-side-container mx-auto">
                        <div className="contact-top-container">
                            <p>
                                Located at the center of attraction for toursit,
                                <span className='fw-bold'>
                                    Our Headquater
                                </span>
                            </p>
                            <div className="contact-details">
                                <div className="call mb-3">
                                <i class="bi bi-whatsapp text-success"></i>
                                    <span className='ms-3'>+977 9846047176 </span>
                                </div>
                                <div className="email mb-3">
                                    <i className="bi bi-envelope-fill text-primary icon"></i>
                                    <span className='ms-3'>info@swissatrek.com</span>
                                </div>
                                <div className="location">
                                    <i className="bi bi-geo-alt-fill text-primary icon"></i>
                                    <span className='ms-3'>Paknajol,Thamel,Kathmandu</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Contact