import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Table from './Table'
import { format, parseISO } from 'date-fns'
const BookingTable = (props) => {
    const [data, setData] = useState(props.bookingInfo)

    const columns = useMemo(() => [
        {
            Header: 'Order ID',
            accessor: 'booking_id'
        },
        {
            Header: 'Title',
            accessor: 'package_name'
        },
        {
            Header: 'Booked For',
            accessor: `booking_date`,
            Cell: ({ value }) => {
                return format(new Date(value), "dd/MM/yyyy");
            },
        },
        {
            Header: 'Duration',
            accessor: 'duration'
        }
    ])

    return (
        <div className="edit-pass-container mt-3 d-flex align-items-center">
            <Table columns={columns} data={data} />
        </div>
    )
}

export default BookingTable