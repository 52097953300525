import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Login.css'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { useAuth } from '../../Components/Auth/auth';
const ForgotPass = () => {
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate()
    const [successMsg, setSuccessMsg] = useState('');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const auth = useAuth();
    const onSubmit = async (data) => {
        try {
            setErrMsg('')
            setSuccessMsg('')
            const response = await axios.post(`${auth.baseURL}/api/reset-password`, {
                email: data.login_username
            },
                { withCredentials: true }
                , {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
            if (response.status === 200) {
                setSuccessMsg(response.data.msg)
            }
        } catch (err) {
            setErrMsg(err?.response?.data?.msg)
            console.log(err)
        }

    }
    return (
        <div className='login-container'>
            <div className="login-wrapper p-md-4">
                <div className="big-heading fs-4 fw-bold text-center">
                    Forgot Password
                </div>
                {errMsg.length > 0 &&
                    <div className="error-display-container">
                        {errMsg}
                    </div>
                }
                {successMsg.length > 0 &&
                    <div className="success-display-container">
                        {successMsg}
                    </div>
                }
                <form onSubmit={handleSubmit(onSubmit)} className='mt-4' action="">
                    <div className="booking-contact-name-container mt-3 px-3">
                        <div className="row mb-4">
                            <label className='px-0 mb-3' htmlFor="username">Username or Email</label>
                            <input type="text"
                                {
                                ...register('login_username', {
                                    required: true,
                                })
                                } className={errors?.username ? "form-control border-danger" : "form-control"} placeholder="" />
                            {errors?.login_username?.type === "required" && <p className='mt-1 text-danger'>This field is required</p>}
                        </div>
                        <div className="row mb-3">
                            <input className='btn primary-btn text-white p-2' type="submit" value={'SEND'} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPass