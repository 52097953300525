import React, { useState, useEffect, lazy, Suspense } from 'react'
import './Home.css'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import scene from '../../image/Home/image-12.jpeg'
import SearchSlider from '../../Components/Slider/SearchSlider'
import Select from '../../Components/Select/Select'
import Activity from '../../Components/Activity/Activity'
import SelectDate from '../../Components/SelectDate/SelectDate'
import SelectPeople from '../../Components/SelectPeople/SelectPeople'
import Package from '../../Components/Package/Package'
import Offer from '../../Components/Offer/Offer'
import axios from 'axios'
import { useAuth } from '../../Components/Auth/auth';
import Loading from '../../Components/Loading/Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Destination = React.lazy(() => import('../../Components/Destination/Destination'));
const ParallexHome = React.lazy(() => import('../../Components/ParallexHome/ParallexHome'))
const Home = () => {
    const notify = () => toast.error("Please select location or activity");
    const [locationSearch, setLocationSearch] = useState('');
    const [activitySearch, setActivitySearch] = useState('');
    const [mindurationSearch, setMinDurationSearch] = useState('');
    const [maxdurationSearch, setMaxDurationSearch] = useState('');
    const [guestSearch, setGuestsSearch] = useState('');
    const [load, setLoad] = useState(false)
    const [topPackage, setTopPackage] = useState([])
    const [category, setCategory] = useState([]);
    const auth = useAuth();
    const [waiting, setWaiting] = useState(false)

    useEffect(() => {
        if (load) {
            if (topPackage.length > 4) {
                setTopPackage(topPackage.slice(0, 4))
            }
        }
    }, [topPackage])

    const navigation = useNavigate();

    const handleSearch = async () => {
        if (locationSearch === '' && activitySearch === '') {
            notify();
        } else {
            setWaiting(true)

        try {

            const response = await axios.post(`${auth.baseURL}/api/searchpackage`, { location: locationSearch, activity: activitySearch, min_duration: mindurationSearch, max_duration: maxdurationSearch, guests: guestSearch })
            navigation('/searchResult', { state: { result: response.data.data } })

        } catch (err) {
            setWaiting(false)
            console.log(err)
        }
        }
    }
    const loadData = async () => {
        try {
            const response = await Promise.all([
                axios.get(`${auth.baseURL}/api/get-package`),
                axios.get(`${auth.baseURL}/api/get-category`)
            ])
            setTopPackage(response[0].data.data)
            setCategory(response[1].data.data)
            setLoad(true)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        loadData()
    }, []);
    return (
        <>
            <div>
                {
                    load ?
                        <>
                            <div className="landing-container">
                    {/* <img className='img-fluid' src={landing} alt="" /> */}
                    <div className="h-100">
                                    <ToastContainer />
                        <div className="landing-text-container h-100">
                            <div className="my-auto">
                                <p className="fancy-font text-center mb-0">
                                    Lets Explore
                                </p>
                                <p className='hook-text mt-0 mb-0 text-center'>Where would you like to go?</p>
                                            <p className=" text-white text-center mt-2">Checkout beautiful places around the world</p>
                            </div>
                                        <div className="landing-search-container p-lg-0 p-2 d-flex flex-wrap justify-content-center rounded m-md-0 w-100 mt-3">
                                <div className="row rounded w-100">
                                    <div className="col-md-5">
                                        <div className="row row-cols-lg-2 row-cols-1">
                                            <div className="col">
                                                <Select setLocationSearch={setLocationSearch} />

                                            </div>
                                            <div className="col">
                                                <Activity setActivitySearch={setActivitySearch} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="row row-cols-lg-2 row-cols-1">
                                            <div className="col">
                                                <SelectDate setMinDurationSearch={setMinDurationSearch} setMaxDurationSearch={setMaxDurationSearch} />
                                            </div>
                                                        <div className="col">
                                                <SelectPeople setGuestsSearch={setGuestsSearch} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-center pe-0">
                                                    {
                                                        waiting ?
                                                            <button className='btn w-100 my-auto search-btn d-flex justify-content-center align-items-center'>
                                                                <span class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
                                                                Searching...
                                                            </button>
                                                            :

                                        <button onClick={handleSearch} className='btn w-100 my-auto search-btn d-flex justify-content-center align-items-center'>
                                                                <span className="material-symbols-outlined me-2">
                                                search
                                            </span>
                                            Search</button>
                                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="other-way-to-search container px-md-5 mb-5 ">
                                <SearchSlider category={category} />
                </div>
                <div className="why-us-container container px-md-5 mb-5">
                    <div className="row row-cols-lg-2 row-cols-1">
                        <div className="col order-lg-0 order-1">
                            <div className="fancy-font fs-2">
                                Why Choose Us?
                            </div>
                            <div className="big-heading">
                                            Plan Your Trip with Swissa's
                            </div>
                            <div className="light-text-bottom pt-4">
                                <p>
                                    There are many variations of passages of available but the majority have suffered alteration in some form, by injected hum randomised words which don't look even slightly.
                                </p>
                            </div>
                            <div className="list-container pt-4 lh-lg">
                                <ul className='check-list'>
                                    <li>
                                        <span>
                                            Invest in your simply neighborhood
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Support people in free text extreme need
                                        </span>
                                    </li>
                                    <li>
                                        <span> Largest global industrial business community</span> </li>
                                </ul>
                            </div>
                            <div className=" pt-4">
                                            <Link to='/contact' className='primary-btn btn'>
                                    Book with us now
                                            </Link>
                            </div>
                        </div>
                        <div className="col order-lg-1 order-0 mb-lg-0 mb-4 text-center position-relative">
                            <div className="grey-circle"></div>
                            <img className='img-fluid circle-img' src={scene} alt="" />
                            <div className="red-circle"></div>
                        </div>
                    </div>
                </div>
                <div className="featured-tours-container container-fluid mb-5 pt-4">
                    <div className="text-center fancy-font fs-1">
                        Featured Tours
                    </div>
                    <div className="big-heading text-center">Most Popular Tours</div>
                    <div className="feature-card-wrapper d-flex mt-4">
                                    {
                                        load &&
                                        <div className='d-flex justify-content-md-start justify-content-center flex-wrap'>
                                            {
                                                topPackage.map((packages) => (
                                                    <Package data={packages} />
                                                ))
                                            }
                                        </div>
                                    }
                    </div>
                </div>
                <div className="top-destination-list container px-md-5 pb-5 mb-5">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Destination />
                                </Suspense>
                </div>
                <div className="our-offer pt-5 mb-5 pb-5">
                    <Offer />
                </div>
                <div className="parallex-home">
                    <ParallexHome />
                </div>
                        </> :
                        <Loading />
                }

            </div>
        </>
    )
}

export default Home