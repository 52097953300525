import React, { useEffect, useState } from 'react'
import './Navbar.css'
import logo from '../../image/company/1.png'
import 'material-icons/iconfont/filled.css';
import 'material-icons/iconfont/outlined.css';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth/auth';
const Navbar = () => {
    const location = useLocation().pathname.slice(1, 5);
    const [showNav, setShowNav] = useState(true);
    const auth = useAuth();
    useEffect(() => {
        { location === 'user' ? setShowNav(false) : setShowNav(true) }
    }, [location])
    return (
        <div>
            {showNav ?
                <>
                    <div className="navbar-top-container">
                        <div className="container py-3 px-md-5">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex contact-top d-md-flex d-none">
                                    <div className="d-flex">
                                    <i class="bi bi-whatsapp text-success"></i>
                                        <p className='ms-3'>+977 9846047176</p>
                                    </div>
                                    <div className="d-flex ms-3">
                                        <i className="bi bi-envelope-fill"></i>
                                        <p className="ms-3">info@swissatrek.com</p>
                                    </div>
                                </div>
                                {/* <div className="">
                                    <div className="social-links-container">
                                        <a target={'_blank'} href="https://www.facebook.com/socialSwissa">
                                            <i className="bi bi-facebook"></i>
                                        </a>
                                        <a target={'_blank'} href="https://www.instagram.com/socialSwissa/">
                                            <i class="bi bi-whatsapp"></i>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <nav className="navbar position-sticky top-0 navbar-expand-lg">
                        <div className="container px-md-5">
                            <a className="navbar-brand" href="#">
                                <img width={100} height={70} src={logo} alt="" />
                            </a>
                            <div className="outside-navigation-cotnainer order-lg-1 order-0 d-flex ms-auto me-md-4 me-2">
                                {auth.isLogin ?
                                    <Link to='/user/dashboard' className="btn profile-container ms-md-4 ms-2">
                                        <span className="material-symbols-outlined">
                                            account_circle
                                        </span>
                                    </Link>
                                    :
                                    <div className='position-relative'>
                                        <div className="btn profile-container ms-md-4 ms-2">
                                            <span className="material-symbols-outlined">
                                                account_circle
                                            </span>
                                            <div className="profile-collapse-container list-unstyled">
                                                <li>
                                                    <Link to='/login'>
                                                        Login
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/register'>
                                                        Register
                                                    </Link>
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="material-symbols-outlined">
                                    menu
                                </span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link to='/' className="nav-link d-flex align-items-center">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/about' className="nav-link d-flex align-items-center">
                                            About Us

                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/destination' className="nav-link d-flex align-items-center">
                                            Destination

                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/package' className="nav-link d-flex align-items-center">
                                            Package

                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/contact' className="nav-link d-flex align-items-center">
                                            Contact
                                        </Link>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </nav>
                </> :
                <>
                    <nav className="navbar dashboard-navbar position-sticky top-0 navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img width={120} height={74} src={logo} alt="" />
                            </a>
                            <div className="outside-navigation-cotnainer order-lg-1 order-0 d-flex ms-auto me-md-4 me-2">
                                <div className=' d-flex align-items-center'>
                                    <Link className='text-danger text-decoration-none d-flex' to='/user/wishlist'>
                                        <span className="material-symbols-outlined me-1">
                                            favorite
                                        </span>
                                        <span className='d-md-block d-none'>Wishlist</span>

                                    </Link>
                                    <div className="btn ms-md-4 d-flex p-0  ms-2">
                                        <span className="material-symbols-outlined me-3">
                                            account_circle
                                        </span>
                                        {/* <span className='d-md-block d-none'>Demo</span> */}
                                    </div>
                                    <button onClick={auth.logout} className="btn d-flex p-0 ms-md-2 ms-0">
                                        <span className="material-symbols-outlined me-md-3">
                                            logout
                                        </span>

                                    </button>
                                </div>
                            </div>
                            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="material-symbols-outlined">
                                    menu
                                </span>
                            </button> */}
                        </div>
                    </nav>
                </>
            }

        </div>
    )
}

export default Navbar