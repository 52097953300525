import React from 'react'
import { useLocation } from 'react-router-dom'
import noResult from '../../image/error/noresult.jpg';
import { Link } from 'react-router-dom';
import Package from '../../Components/Package/Package';


const SearchResult = () => {
    const { state } = useLocation();
    const totalStars = 5;
    const activeStars = 4;
    return (
        <div className='px-md-4 px-2 py-3'>
            <div className="fs-3 fw-bold my-3">Search Result</div>
            <div className="feature-card-wrapper d-flex mt-4">
                {state.result.length > 0 ?
                    <div className='d-flex flex-wrap'>
                        {state.result.map((res) => (
                            <Package data={res} />
                        ))}
                    </div> :
                    <div className='mx-auto'>
                        <img width={300} height={300} className='img-fluid mx-auto' src={noResult} alt="" />
                        <h3 className='text-center mt-3'>No Result found</h3>
                    </div>
                }
            </div>

        </div>
    )
}

export default SearchResult