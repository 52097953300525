import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React from 'react'
import './SearchSlider.css'
import axios from "axios";
import { useAuth } from "../Auth/auth";
import { Link, Navigate, useNavigate } from 'react-router-dom'

const SearchSlider = ({ category }) => {
    const auth = useAuth();
    const navigation = useNavigate();

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#21211e64", borderRadius: "50%", padding: '10px', width: '40px', height: '40px' }}
                onClick={onClick}
            />
        );
    }


    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#21211e64", borderRadius: "50%", padding: '10px', width: '40px', height: '40px' }}
                onClick={onClick}
            />
        );
    }

    const handleSearch = async (id) => {
        try {
            const response = await axios.post(`${auth.baseURL}/api/search-by-category`, { activity: id, })
            navigation('/searchResult', { state: { result: response.data.data } })

        } catch (err) {
            console.log(err)
        }
    }


    var settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,

        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true
    };
    return (
        <div className="test px-md-0 px-3">
            <Slider {...settings}>
                {category.map((ca, index) => (
                    <div onClick={(e) => { handleSearch(ca.id) }} key={ca.index} className="">
                        <div className="search-card mx-auto">
                            <div className="icons-container">
                                <div className="adventure-icon" dangerouslySetInnerHTML={{ __html: `${ca.icon}` }}></div>
                            </div>
                            <div className="card-name">{ca.name}</div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default SearchSlider